import React from "react"
import ProductPage from "../components/ProductPage/productPage";
import poi from '../images/gifs/pp_poi.gif';
import punkty from '../images/gifs/pp_punkty.gif';
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const data = (intl) => {
  return {
    title: intl.formatMessage({ id: 'mapy.title' }),
    desc: intl.formatMessage({ id: 'mapy.desc' }),
    anchors: ['', intl.formatMessage({ id: 'nav.produkty.mapy.poi.slug' }), intl.formatMessage({ id: 'nav.produkty.mapy.punkty.slug' }), ''],
    sections: [
      // {
      //   title: "Mapy potencjału (populacja, zarobki)",
      //   desc: "Używaj gotowych warstw potencjałowych i swoich aplikacjach. Za pomocą usług WMS łącz dane użytkownika z mikro-przestrzennymi statystykami obrazującymi m.in. gęstość zaludnienia, liczbę miejsc pracy, zarobki czy atrakcyjność handlową.",
      //   img: "telefon2.png",
      //   link: null,
      //   widget: "strefy",
      //   imageRight: true,
      //   background: "01_blue.png"
      // },
      {
        title: intl.formatMessage({ id: 'mapy.poi.title' }),
        desc: intl.formatMessage({ id: 'mapy.poi.desc' }),
        img: poi,
        link: "https://api.locit.io/docs/?urls.primaryName=spatial-query-poi-v2.2.0",
        target: "blank",
        widget: null,
        widgetVersion: null,
        imageRight: false,
        background: "02_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'mapy.punkty.title' }),
        desc: intl.formatMessage({ id: 'mapy.punkty.desc' }),
        img: punkty,
        link: "https://api.locit.io/docs/?urls.primaryName=spatial-query-address-v2.2.0",
        target: "blank",
        widget: null,
        widgetVersion: null,
        imageRight: true,
        background: "03_blue.png"
      }
    ],
    features: [
      {
        text: intl.formatMessage({ id: 'features.devs' }),
        icon: "devs"
      },
      {
        text: intl.formatMessage({ id: 'features.analytics' }),
        icon: "analytics"
      },
      {
        text: intl.formatMessage({ id: 'features.ecommerce' }),
        icon: "ecommerce"
      }
    ]
  }
}

const Mapy = (props) => (
  <ProductPage data={data(props.intl)} uri={props.uri} pathname={props.location.pathname} />
)

Mapy.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(Mapy)
